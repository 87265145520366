const list = document.querySelectorAll('.js-list');
const inner = document.querySelectorAll('.js-view');
const linkInner = document.querySelectorAll('.js-link-inner');
const link = document.querySelectorAll('.js-link');

/**
 * マウスオーバーした際、ドロップダウンメニューを表示させる
 */
export function viewInnerMenu() {
  for (let i = 0; i < list.length; i++) {
    list[i].addEventListener('mouseover', function () {
      inner[i].classList.add('is-view');
      linkInner[i].classList.add('is-view');
    });
    list[i].addEventListener('mouseleave', function () {
      inner[i].classList.remove('is-view');
      linkInner[i].classList.remove('is-view');
    });
  }
}

/**
 * マウスオーバーした際、ヘッダーメニューのリンクの色を変える
 */
export function changeLinkColor() {
  for (let i = 0; i < link.length; i++) {
    link[i].addEventListener('mouseover', function () {
      link[i].classList.add('is-view');
    });
    link[i].addEventListener('mouseleave', function () {
      link[i].classList.remove('is-view');
    });
  }
}
