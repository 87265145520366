/**
 * ハンバーガーメニューのマークを変形させる
 */
export function openHamburger() {
  const box = document.querySelector('.js-hamburger-box');
  const wrap = document.querySelector('.js-hamburger-wrap');
  const body = document.body;
  box.addEventListener('click', function () {
    box.classList.toggle('is-open');
    wrap.classList.toggle('is-open');
    body.classList.toggle('is-open');
  });
}
