import { openHamburger } from './modules/hamburger';
import { viewInnerMenu, changeLinkColor } from './modules/menu';
import { scrollHint } from './modules/scrollHint';
import { swiper } from './modules/swiper';
import { smooth } from './modules/smooth';

openHamburger();
changeLinkColor();
viewInnerMenu();
scrollHint();
swiper();
smooth();
